.gallery_carousel {

	.carousel-indicators {

			margin: 60px 0 0;

			@media only screen and ($small-desktop) {

				margin: 20px 0;
			}

			li {
				
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.5);
				display: inline-block;

				&.active {

					background-color: rgba(255, 255, 255, 1);
				}
			}
		}

		.carousel-inner {

			-webkit-box-shadow: 0px 0px 21px 5px rgba(255,255,255,0.6);
			-moz-box-shadow: 0px 0px 21px 5px rgba(255,255,255,0.6);
			box-shadow: 0px 0px 21px 5px rgba(255,255,255,0.6);
		}

		.carousel-control-next {

			@media only screen and ($device-max) {

				width: 30px;
				height: 30px;
				top: initial;
				bottom: 0px;
				right: 0;
				opacity: 1;

			}

			.carousel-control-next-icon {

				background-image: url('../images/components/arrow-right.svg') !important;
				width: 60px !important;
				height: 60px !important;
			}

			@media only screen and ($small-desktop) {

				right: -150px !important;
			}
		}

		.carousel-control-prev {

			@media only screen and ($device-max) {

				width: 30px;
				height: 30px;
				top: initial;
				bottom: 0px;
				opacity: 1;

			}

			.carousel-control-prev-icon {

				background-image: url('../images/components/arrow-left.svg') !important;
				width: 60px;
				height: 60px;
			}

			@media only screen and ($small-desktop) {

				left: -150px !important;
			}
		}

	.carousel-inner {

		box-shadow: none !important;
	}

	.carousel-item {

		height: 300px;

		@media only screen and ($small-desktop) {

			height: 600px;
		}

		img {

			display: block;
			margin: 0 auto;
			border: 2px solid white;

			@media only screen and ($device-max) {

				width: 100%;
				height: auto !important;
			}
		}
	}
}