.archive_gallery_wrapper {

	background-color: #FFF;
	padding: 0 15px 60px;
	position: relative;

	.woody {

		position: absolute;

		&-top {

			width: 50px;
			height: 26px;
			transform: rotate(180deg);
			top: -3px;
			right: 30%;
		}

		&-bottom {

			width: 50px;
			height: 26px;
			bottom: -3px;
			left: 30%;
		}
	}

	.gallery_cats_wrapper {

		background-color: #CCC;
		margin-bottom: 20px;
		padding-top: 20px;
	}

	.gallery_cats {

		padding: 0;
		margin: 20px auto 30px;
		text-align: center;


		li {

			display: inline-block;
			font-size: $baseFontSize * 1.4;

			&:not(:last-child) {

				margin-right: 20px;

				&:after {

					content: '•';
					color: black;
					font-size: $baseFontSize;
					padding-left: 20px;
				}
			}

			a {

				color: black;
				font-family: $headingFontReversed;

				&.active_tab {

					font-family: $headingFont;
				}
			}
		}
	}

	.mobile_gallery_link {

		h3 {

			font-size: 28px;
			color: black;
			font-family: $headingFontReversed;
			text-align: center;
			padding: 15px 0;

			@media only screen and ($device-max) {

				margin-bottom: 30px;
			}
		}

	}

	.gallery_item {

		width: 100%;
		padding-bottom: 100%;
		position: relative;
		background-size: cover;
		background-position: center center;
		
		@media only screen and ($tablet-viewport) {

			margin-bottom: 30px;
		}


		&:hover {

			h3 {

				opacity: 1;
			}

			.gallery_item_link {

				background-color: rgba($primaryColor, 0.7);
			}
		}

		h3 {

			@include absoluteCenter;
			@include multiTransition;
			font-size: 28px;
			color: $secondaryColor;
			font-family: $headingFont;
			text-align: center;
			z-index: 3;
			opacity: 0;
		}

		.gallery_item_link {

			@include multiTransition;
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;
			display: block;
			z-index: 2;
		}
	}
}