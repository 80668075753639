.strip_button {

	@include absoluteCenter;
	@include multiTransition;
	display: block;
	font-family: $headingFontReversed;
	color: $secondaryColor;
	background-color: rgba($primaryColor, 0.75);
	padding: 6px 15px;
	width: 90%;
	text-align: center;
	font-size: $baseFontSize * 1.875;
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);

	@media only screen and ($small-desktop) {

		width: auto;
		font-size: $baseFontSize * 2;

	}

	&:hover {

		text-decoration: none;
		background-color: rgba($secondaryColor, 0.75);
		color: $primaryColor;
	}
}

.content_wrapper {

	@media only screen and ($small-desktop) {

		margin-top: 83px;
	}
}

.image_preview_frame {

	@include multiTransition;
	width: 100%;
	height: 250px;
	overflow: hidden;
	position: relative;
	margin: 15px 0;

	&:hover {

		border: 3px solid white;
	}

	img {

		@include absoluteCenter;
		width: 100%;
	}
}

.woody_holder {

	width: 100%;
	height: calc(100vh - 200px);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #000000; /* Old browsers */
	background: -moz-linear-gradient(left, #000000 0%, #4d4d4d 50%, #000000 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #000000 0%,#4d4d4d 50%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #000000 0%,#4d4d4d 50%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */


	.woody_holder_inner {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		img {

			display: block;
			margin: 20px auto 0;
		}

		h1 {

			transform: rotate(-3.5deg);
			color: white;
			font-size: $baseFontSize * 2.4;
			font-family: $headingFont;
			
			@media only screen and ($small-desktop) {

				font-size: $baseFontSize * 4.375;
			}
		}

		h2 {

			transform: rotate(-3.5deg);
			color: white;
			font-size: $baseFontSize * 2.4;
			font-family: $headingFontReversed;
			
			@media only screen and ($small-desktop) {

				font-size: $baseFontSize * 4.375;
			}
		}

		p {

			font-family: $bodyFont;
			font-size: $baseFontSize;
			text-align: center;
			color: white;
		}

	}

}

.woody_map {

	height: 300px;

	@media only screen and ($small-desktop) {

		height: 600px;
	}
}