.footer_wrapper {

	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,4d4d4d+50,000000+100 */
	background: #000000; /* Old browsers */
	background: -moz-linear-gradient(left, #000000 0%, #4d4d4d 50%, #000000 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #000000 0%,#4d4d4d 50%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #000000 0%,#4d4d4d 50%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
	padding: 40px 0;
	position: relative;

	.back_to_top {

		font-family: $headingFontReversed;
		color: $primaryColor;
		font-size: 28px;
		position: absolute;
		background-color: $secondaryColor;
		padding: 0 10px;
		left: 50%;
		transform: translateX(-50%) rotate(-3.5deg);
		top: 0;
		width: 90%;
	    text-align: center;

		@media only screen and ($small-desktop) {

			transform: translateX(-50%) rotate(-7.5deg);
			top: -20px;
			width: auto;
		}
	}

	p {

		color: white;
		font-family: $headingFontReversed;
		font-weight: 400;
		font-size: $baseFontSize;

		a {

			color: white;
			text-decoration: none;
		}
	}
}