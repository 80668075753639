.hero_image {

	background-repeat: no-repeat;
	position: relative;
	min-height: 300px;
	background-position: right;
	background-size: auto 300px;

	&:before, &:after {

		content: '';
		width: 100%;
		height: 20px;
		display: block;
		position: absolute;
		z-index: 3;
	}

	&:before {

		top: 0;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
		background: -moz-linear-gradient(top, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
	}

	&:after {

		bottom: 0;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	}

	@media only screen and ($small-desktop) {

		width: 100%;
		height: 500px;
		background-size: cover;
		background-position: center;
	}

	.hero_overlay {

		background-color: rgba(0,0,0, 0.2);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	h1 {

		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%) rotate(-3.5deg);
		color: white;
		font-size: $baseFontSize * 2.4;
		font-family: $headingFont;
		z-index: 2;
		width: 90%;
		text-align: center;

		@media only screen and ($small-desktop) {

			font-size: $baseFontSize * 4.375;
			width: 100%;
		}
	}
}