@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,800');

@font-face {
    font-family: 'impact_labelregular';
    src: url('../fonts/impact_label-webfont.woff2') format('woff2'),
         url('../fonts/impact_label-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'impact_label_reversedregular';
    src: url('../fonts/impact_label_reversed-webfont.woff2') format('woff2'),
         url('../fonts/impact_label_reversed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

$bodyFont: 'Open Sans', sans-serif;
$headingFont: 'impact_labelregular', sans-serif;
$headingFontReversed: 'impact_label_reversedregular', sans-serif;