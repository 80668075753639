.home_wrapper {

	.hero_image {

		.home_hero_logo {

			width: 40%;
			position: absolute;
			bottom: 20px;
			left: 20px;

			@media only screen and ($small-desktop) {

				@include absoluteCenter;
				width: 25%;
			}
		}
	}

	.content_strip-black {

		@media only screen and ($small-desktop) {

			padding-top: 80px;

		}

		h1 {

			font-family: $headingFontReversed;
			color: black;
			background-color: white;

			@media only screen and ($device-max) {

				margin-bottom: 30px;
				transform: rotate(-3.5deg);
			}

			@media only screen and ($small-desktop) {

				left: 50%;
				top: -120px;
				transform: rotate(-7.5deg) translateX(-50%);
				position: absolute;

			}

		}
	}

	.homepage_strip {

		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		min-height: 500px;

		@media only screen and ($small-desktop) {

			background-attachment: fixed;
		}

		&:before, &:after {

			content: '';
			width: 100%;
			height: 20px;
			display: block;
			position: absolute;
			z-index: 3;
		}

		&:before {

			top: 0;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
			background: -moz-linear-gradient(top, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
		}

		&:after {

			bottom: 0;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
			background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
		}

		@media only screen and ($small-desktop) {

			width: 100%;
			height: 500px;
		}

		.work_strip_button {

			&:after {

				width: 30px;
				height: 30px;
				content: ' ';
				background-image: url('../images/components/arrow-right.svg');
				background-position: center;
				background-repeat: no-repeat;
				display: inline-block;
				vertical-align: middle;
			}

		}
	}

	.content_strip-latest-work {

		h2 {

			text-align: center;
			font-family: $headingFontReversed;
			background-color: transparent;
			color: white;
			font-size: $baseFontSize * 2.4;
		}
	}
}