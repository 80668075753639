.related_item_title {

	font-family: $headingFontReversed;
	font-size: $baseFontSize * 1.5625;

	a {

		color: white;
	
		&:hover {

			font-family: $headingFont;
		}
	}
}