@mixin multiTransition($duration: 0.4s, $type: ease-out) {

    -webkit-transition: all $duration $type;
    -ms-transition: all $duration $type;
    -moz-transition: all $duration $type;
    -o-transition: all $duration $type;
    transition: all $duration $type;
}

@mixin verticalCenter($position: absolute) {

    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin horizontalCenter($position: absolute) {

    position: $position;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

@mixin absoluteCenter($position: absolute) {

    position: $position;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin verticalThirdCenter($position: absolute) {

    position: $position;
    top: 35%;
    left: 50%;
    -webkit-transform: translate(-50%, -65%);
    -ms-transform: translate(-50%, -65%);
    -moz-transform: translate(-50%, -65%);
    -o-transform: translate(-50%, -65%);
    transform: translate(-50%, -65%);
}