.content_strip {

	padding: 30px 0;
	text-align: center;

	@media only screen and ($small-desktop) {

		padding: 50px 0;
	}

	h1 {

		font-family: $headingFont;
	}

	h2, h3 {

		font-family: $headingFontReversed;
	}

	p {

		font-family: $bodyFont;
		font-weight: 400;
	}

	&-latest-work {

		h2 {

			margin-bottom: 50px;
		}

		.latest_work_link {

			&:hover {

				text-decoration: none;
			}
		}

	}
	

	&-black {

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,4d4d4d+50,000000+100 */
		background: #000000; /* Old browsers */
		background: -moz-linear-gradient(left, #000000 0%, #4d4d4d 50%, #000000 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #000000 0%,#4d4d4d 50%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #000000 0%,#4d4d4d 50%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */

		h1, p {

			color: $secondaryColor;
		}

		h2, h3 {

			color: $primaryColor;
			background-color: $secondaryColor;
		}
	}

	&-white {

		background-color: $secondaryColor;

		h1, p {

			color: $primaryColor;
		}

		h2, h3 {

			color: $primaryColor;
			font-family: $headingFont;
			transform: rotate(-3.5deg);
			margin-bottom: 25px;
			width: auto;
		}
	}

	&-grey {

		background-color: #CCC;
	}

	&-shop {

		height: 350px;
		position: relative;
		background-position: center;
		background-size: cover;

		.woody {

			position: absolute;
			bottom: 0;
			left: 20%;
		}

		&:before, &:after {

			content: '';
			width: 100%;
			height: 20px;
			display: block;
			position: absolute;
			z-index: 3;
		}

		&:before {

			top: 0;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
			background: -moz-linear-gradient(top, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255,255,255,0.6) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
		}

		&:after {

			bottom: 0;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
			background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
		}

		@media only screen and ($small-desktop) {

			.strip_button {

				&:before {

					content: '';
					width: 48px;
					height: 48px;
					background-image: url('../images/components/shopping-cart-grey.svg');
					background-repeat: no-repeat;
					background-position: center;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
}