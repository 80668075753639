.instagram_feed {

	h3 {

		font-size: $baseFontSize * 2;
		word-break: break-word;

		@media only screen and ($small-desktop) {

			font-size: $baseFontSize * 3.8;
		}

		a {

			color: black;

			&:hover {

				text-decoration: none;
			}
		}
	}

	.insta_thumb {

		@include multiTransition;
		width: 100%;
    	height: 260px;
    	background-size: cover;
    	margin-bottom: 30px;

    	@media only screen and ($small-desktop) {

    		width: 260px;
    	}

    	&:hover {

	    	transform: scale(1.02);
	    	opacity: 0.8;
	    }

    	a {

    		display: block;
		    overflow: hidden;
		    position: absolute;
		    width: 100%;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    z-index: 3;
    	}
	}
}