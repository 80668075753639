.contact_intro {

	text-align: left;
	border-bottom: 1px solid white;
	margin-bottom: 15px;
	font-weight: 400;

	p {

		color: white;
		font-weight: 400;
		font-family: $bodyFont;

		a {

			color: white;
		}
	}

	span {

		font-family: $headingFont;
		color: white;
		font-size: $baseFontSize * 1.4;
	}
}