.navigation_wrapper {

	@media only screen and ($small-desktop) {

		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99;
	}

	.banner_wrapper {

		background-color: $primaryColor;
		padding: 10px;

		.site_logo_wrapper {

			display: block;
			text-align: center;
		}

		.navbar {

			@media only screen and ($device-max) {

				.main_navigation {

					margin-top: 40px;
				}
			}

			.nav_wrapper {

				text-align: center;

				.navbar-nav {

					display: inline-block;
					padding-left: 0;

					li {

						display: block;

						&.social_nav {

							display: inline-block;

							@media only screen and ($device-max) {

								padding-top: 15px;
							}

						}

						@media only screen and ($small-desktop) {

							display: inline-block;
						}

						&.active {

							a {

								font-family: $headingFont;
							}
						}

						a {

							font-family: $headingFontReversed;
							color: $lightGrey;
							font-size: $baseFontSize * 1.125;
							padding: 5px 20px;

							@media only screen and ($small-desktop) {

								padding: 10px 20px;
							}

							&:hover {

								font-family: $headingFont;
							}
						}
					}
				}

				&.right_nav {

					li {

						&:nth-last-child(2) {

							margin-right: 10px;

							a {

								padding: 0;
							}
						}

						&:last-child {

							a {

								padding: 0;
							}
						}
					}
				}
			}
		}
	}
}