form {

	label {

		font-family: $headingFontReversed;
		color: white;
		font-size: $baseFontSize * 1.4;
		text-align: left;
		width: 100%;
	}

	input[type="text"], input[type="email"], textarea {

		border-radius: 0 !important;
		border: 0 !important;
		background-color: white !important;
	}

	input[type='submit'] {

		font-family: $headingFont;
		color: white;
		font-size: $baseFontSize * 1.8;
		background-color: transparent;
		border: none;
		transform: rotate(-3.5deg);
	}
}